import { Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React, { FC } from "react"
import { Wrapper } from "../common/wrapper"
import { Card } from "./Card"
import { Icon, IconName, tooltip } from "./Icon"
import { IMG_PREFIX, IMG_SUFFIX } from "./theme"

type TClientsImgProps = {
  imgSrc: string
}

const ClientsImg: React.FC<TClientsImgProps> = ({ imgSrc }) => {
  return (
    <>
      <source
        srcSet={`${IMG_PREFIX}/w_220,f_webp${IMG_SUFFIX}/${imgSrc} 1170w,
              ${IMG_PREFIX}/w_120,f_webp${IMG_SUFFIX}/${imgSrc} 750w`}
        type="image/webp"
      />
      <source
        srcSet={`${IMG_PREFIX}/w_220,f_jpg${IMG_SUFFIX}/${imgSrc} 1170w,
              ${IMG_PREFIX}/w_120,f_jpg${IMG_SUFFIX}/${imgSrc} 750w`}
        type="image/jpg"
      />
    </>
  )
}

export const Clients: React.FC = () => {
  const classes = useStyles()
  return (
    <Wrapper id="portfolio">
      <h2>Portfolio</h2>
      <br />
      <div className={classes.inner}>
        <Card
          imgSrc="/clients/pokerru_2x.png"
          heading={
            <a target="_blank" href="https://poker.ru">
              Poker.ru
            </a>
          }
          description="Top Poker site in Runet."
          source={<ClientsImg imgSrc="/clients/pokerru_2x.png" />}
          iconTag={[
            IconName.wordpress,
            IconName.docker,
            IconName.cloudflare,
            IconName.webpack,
            IconName.cdn
          ].map(item => (
            <Icon key={item} icon={item} tooltip={tooltip[item]} />
          ))}
        />
        <Card
          imgSrc="/clients/BNB-logo.svg"
          heading={
            <a target="_blank" href="https://betnbet.ru/">
              Betnbet.ru
            </a>
          }
          description="Biggest betting forecasts portal in Runet."
          iconTag={[
            IconName.ts,
            IconName.react,
            IconName.nodejs,
            IconName.webpack,
            IconName.materialUi,
            IconName.cdn
          ].map(item => (
            <Icon key={item} icon={item} tooltip={tooltip[item]} />
          ))}
          iconScore={
            <>
              <PagespeedIcon link="https://developers.google.com/speed/pagespeed/insights/?url=https%3A%2F%2Fbetnbet.ru%2F&tab=desktop" />
            </>
          }
        />
        <Card
          imgSrc="/clients/LP-logo.svg"
          heading={
            <a target="_blank" href="https://lesplatanes.co.uk/">
              Les Platanes
            </a>
          }
          description="Contemporary French Bistrot De Luxe in central London."
          iconTag={[
            IconName.ts,
            IconName.react,
            IconName.cloudflare,
            IconName.seo,
            IconName.nodejs,
            IconName.webpack,
            IconName.cdn
          ].map(item => (
            <Icon key={item} icon={item} tooltip={tooltip[item]} />
          ))}
          iconScore={
            <>
              <PagespeedIcon link="https://developers.google.com/speed/pagespeed/insights/?hl=RU&url=https%3A%2F%2Flesplatanes.co.uk%2F&tab=desktop" />
              <WebpagetestIcon link="https://www.webpagetest.org/result/190919_5D_c403e01b905d06d1645fc016630d1bd1/" />
            </>
          }
        />

        <Card
          imgSrc="/clients/freighthub.svg"
          heading={
            <a target="_blank" href="https://freighthub.com/en/">
              FreightHub
            </a>
          }
          description="The Digital Freight Forwarder."
          iconTag={[
            IconName.ts,
            IconName.react,
            IconName.nodejs,
            IconName.jest,
            IconName.webpack,
            IconName.cdn
          ].map(item => (
            <Icon key={item} icon={item} tooltip={tooltip[item]} />
          ))}
        />
        <Card
          imgSrc="/clients/RDC-logo.svg"
          heading={
            <a target="_blank" href="https://red-dragon.palessit.dev/">
              Red dragon casino
            </a>
          }
          description="Online casino."
          iconTag={[
            IconName.ts,
            IconName.react,
            IconName.cloudflare,
            IconName.aws,
            IconName.lambda,
            IconName.nodejs,
            IconName.webpack,
            IconName.materialUi,
            IconName.cdn
          ].map(item => (
            <Icon key={item} icon={item} tooltip={tooltip[item]} />
          ))}
        />
        <Card
          imgSrc="/clients/online-slot.png"
          heading={
            <a target="_blank" href="https://www.online-slot.co.uk/">
              Online-Slot.co.uk
            </a>
          }
          description="One of the biggest online casino in UK."
          source={<ClientsImg imgSrc="/clients/online-slot.png" />}
          iconTag={[IconName.wordpress, IconName.cloudflare, IconName.cdn].map(
            item => (
              <Icon key={item} icon={item} tooltip={tooltip[item]} />
            )
          )}
        />
        <Card
          imgSrc="/clients/c-manual.png"
          heading={
            <a target="_blank" href="https://www.casinomanual.co.uk">
              Casinomanual.co.uk
            </a>
          }
          description="The most trusted online casinos in the UK."
          source={<ClientsImg imgSrc="/clients/c-manual" />}
          iconTag={[IconName.wordpress, IconName.cloudflare, IconName.cdn].map(
            item => (
              <Icon key={item} icon={item} tooltip={tooltip[item]} />
            )
          )}
        />
        <Card
          imgSrc="/clients/best-slot.png"
          heading={
            <a target="_blank" href="https://www.bestslots.co.uk/">
              BestSlots
            </a>
          }
          description="Biggest online slot game reviews in UK."
          source={<ClientsImg imgSrc="/clients/best-slot.png" />}
          iconTag={[IconName.wordpress, IconName.cloudflare, IconName.cdn].map(
            item => (
              <Icon key={item} icon={item} tooltip={tooltip[item]} />
            )
          )}
        />
        <Card
          imgSrc="/clients/casino.png"
          heading={
            <a target="_blank" href="https://casino.ru/">
              Casino.ru
            </a>
          }
          description="Top casino site in Runet."
          source={<ClientsImg imgSrc="/clients/casino.png" />}
          iconTag={[IconName.wordpress, IconName.cloudflare, IconName.cdn].map(
            item => (
              <Icon key={item} icon={item} tooltip={tooltip[item]} />
            )
          )}
        />
        <Card
          imgSrc="/clients/amhgroup.png"
          heading={
            <a target="_blank" href="https://amhgroup.ru/">
              AMH Group
            </a>
          }
          description="Upkeep of pipeline sysyems as well as provides engineering services with complete automation"
          source={<ClientsImg imgSrc="/clients/amhgroup.png" />}
          iconTag={[
            IconName.wordpress,
            IconName.cloudflare,
            IconName.docker,
            IconName.webpack
          ].map(item => (
            <Icon key={item} icon={item} tooltip={tooltip[item]} />
          ))}
          iconScore={
            <Icon
              icon={IconName.pagespeed}
              tooltip={tooltip[IconName.pagespeed]}
              link="https://developers.google.com/speed/pagespeed/insights/?hl=RU&url=https%3A%2F%2Famhgroup.ru%2F&tab=desktop"
            />
          }
        />
      </div>
    </Wrapper>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    inner: {
      maxWidth: theme.palessit.innerMaxWidth,
      margin: "0 auto",
      display: "grid",
      justifyItems: "center",
      gridColumnGap: "16px",
      gridRowGap: "16px",
      gridTemplateColumns: "repeat( auto-fit, minmax(280px, 1fr) )",
      [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "repeat( auto-fit, minmax(320px, 1fr) )"
      }
    }
  }),
  { name: "Clients" }
)

type TIconLinkProps = {
  link: string
}
const PagespeedIcon: FC<TIconLinkProps> = ({ link }) => (
  <Icon
    icon={IconName.pagespeed}
    tooltip={tooltip[IconName.pagespeed]}
    link={link}
  />
)

const WebpagetestIcon: FC<TIconLinkProps> = ({ link }) => (
  <Icon
    icon={IconName.webpagetest}
    tooltip={tooltip[IconName.webpagetest]}
    link={link}
  />
)
