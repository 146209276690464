import { createBrowserHistory } from "history"

export function hashLinkScroll(hash: string, instant?: boolean) {
  if (hash === "") {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }
  if (hash !== "") {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace("#", "")
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({
          behavior: instant ? ("instant" as any) : "smooth"
        })
      }
    }, 0)
  }
}

export const initHistory = () => {
  const history = createBrowserHistory()
  let pathname: string = window.location.pathname

  type TWithDataLayer = { dataLayer: any[] }
  const w = (window as any) as TWithDataLayer
  w.dataLayer = w.dataLayer || []
  const dataLayer = w.dataLayer

  history.listen((location, _action) => {
    // https://www.bounteous.com/insights/2018/03/30/single-page-applications-google-analytics/
    dataLayer.push({
      event: "pageview",
      page: {
        path: window.location.pathname
      }
    })

    let isInstant: boolean = false
    if (pathname !== location.pathname) {
      isInstant = true
      pathname = location.pathname
    }
    hashLinkScroll(location.hash, isInstant)
  })
  return history
}
