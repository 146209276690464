import React from "react"
import { HiringHero } from "./hiring-hero"
import { Jobs } from "./hiring-jobs"
import { HiringTeam } from "./hiring-team"

export const HiringPage = () => (
  <div>
    <HiringHero />
    <HiringTeam />
    <Jobs />
  </div>
)
