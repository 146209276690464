import { Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import React from "react"
import { IMG_PREFIX, IMG_SUFFIX } from "./theme"

type TOurStackLibProps = {
  imgSrc: string
  source?: React.ReactNode
  heading: React.ReactNode
  description?: string | string[] | React.ReactNode
  alignRight?: boolean
  iconTag?: any
  iconScore?: any
} & React.HTMLAttributes<HTMLDivElement>
export const Card: React.FC<TOurStackLibProps> = props => {
  const {
    imgSrc,
    description: text,
    heading: title,
    alignRight,
    className,
    source,
    iconTag,
    iconScore,
    ...rest
  } = props
  const classes = useStyles()
  return (
    <div
      className={clsx(
        classes.root,
        className,
        alignRight ? " " + classes.wrapperRight : null
      )}
      {...rest}
    >
      <picture className={classes.background}>
        {source}
        <img
          src={IMG_PREFIX + IMG_SUFFIX + imgSrc}
          className={classes.backgroundImage}
          {...{ loading: "lazy" }}
        />
        <picture className={classes.background}>
          {source}
          <img
            src={IMG_PREFIX + IMG_SUFFIX + imgSrc}
            className={classes.backgroundImage}
            {...{ loading: "lazy" }}
          />
        </picture>
      </picture>
      <picture className={classes.picture}>
        {source}
        <img
          {...{ loading: "lazy" }}
          src={IMG_PREFIX + IMG_SUFFIX + imgSrc}
          className={classes.img}
        />
      </picture>
      <div className={classes.title}>{title}</div>
      <div className={classes.description}>{text}</div>
      <div className={classes.iconsV2}>
        <div className={classes.iconTag}>{iconTag}</div>
        <div className={classes.iconScore}>{iconScore}</div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      maxWidth: "500px",
      width: "100%",
      position: "relative",
      display: "grid",
      gridTemplateAreas: '"img title" "img description" "iconsV2 iconsV2"',
      gridColumnGap: "16px",
      gridTemplateRows: "auto minmax(0px, 1fr) auto",
      gridTemplateColumns: "100px auto",
      textAlign: "left",
      boxShadow: theme.palessit.cardShadow,
      borderRadius: "5px",
      overflow: "hidden",
      padding: "1rem",
      fontSize: "14px",
      ["@media (min-width: 460px)" as any]: {
        fontSize: "1rem",
        gridTemplateColumns: "160px auto"
      }
    },
    wrapperRight: {
      gridTemplateAreas: '"title img" "description img" "iconsV2 iconsV2"',
      gridTemplateColumns: "auto 100px",
      textAlign: "right",
      ["@media (min-width: 460px)" as any]: {
        gridTemplateColumns: "auto 160px"
      }
    },
    background: {
      position: "absolute",
      zIndex: 0,
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      opacity: 0.05
    },
    backgroundImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover"
    },
    picture: {
      gridArea: "img",
      maxWidth: "100%",
      alignSelf: "center",
      zIndex: 5
    },
    img: {
      maxWidth: "100%",
      borderRadius: "5px"
    },
    title: {
      gridArea: "title",
      fontSize: "20px",
      zIndex: 5,
      ["@media (min-width: 460px)" as any]: {
        fontSize: "24px"
      }
    },
    description: {
      fontSize: "13px",
      gridArea: "description",
      zIndex: 5,
      whiteSpace: "pre-line",
      ["@media (min-width: 460px)" as any]: {
        fontSize: "14px"
      }
    },
    iconsV2: {
      gridArea: "iconsV2",
      position: "relative",
      zIndex: 2,
      display: "flex",
      justifyContent: "space-between",
      marginTop: "12px"
    },
    iconTag: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center"
    },
    iconScore: {
      display: "flex",
      flexWrap: "nowrap"
    }
  }),
  { name: "Card" }
)
