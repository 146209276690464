import React from "react"
import { Clients } from "./Clients"
import { Hero } from "../home/hero/Hero"
import { Offers } from "./Offers"
import { OurStack } from "./OurStack"
import { WhatWeDo } from "./WhatWeDo"

export const HomePage = () => (
  <>
    <Hero />
    <WhatWeDo />
    <Offers />
    {/* <Pricing /> */}
    <OurStack />
    <Clients />
  </>
)
