import { makeStyles, Typography } from "@material-ui/core"
import React, { FC, useEffect, useRef, useState } from "react"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import { CSSTransitionProps } from "react-transition-group/CSSTransition"
import { Arrows } from "./hero-arrows"
import { Dots } from "./hero-dots"
import { SLIDER_CONFIG, slides } from "./hero-slides"

const FadeTransition: FC<Omit<CSSTransitionProps, "timeout">> = props => (
  <CSSTransition
    {...props}
    classNames="fade"
    timeout={{ enter: SLIDER_CONFIG.enter, exit: SLIDER_CONFIG.exit }}
  />
)

type THeroSliderProps = {
  // slides: TSlide[]
}
export const HeroSlider: FC<THeroSliderProps> = ({}) => {
  const [index, setIndex] = useState<number>(0)
  const timer_ref = useRef<number | null>(null)

  const clear = () => {
    if (timer_ref.current) {
      window.clearTimeout(timer_ref.current)
      timer_ref.current = null
    }
  }
  const _setIndex = (index: number) => {
    clear()
    setIndex(index)
  }
  useEffect(() => {
    timer_ref.current = window.setTimeout(() => {
      const newIndex = index + 1 === slides.length ? 0 : index + 1
      setIndex(newIndex)
    }, SLIDER_CONFIG.transition)
    return clear
  }, [index])

  const slide = slides[index]
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.animated}>
        <SwitchTransition>
          <FadeTransition
            key={index}
            addEndListener={(node: any, done: any) =>
              node.addEventListener("transitionend", done, false)
            }
            classNames="fade"
          >
            <div className={classes.inner}>
              <img className={classes.image} src={slide.image} />
              <div className={classes.text_block}>
                <Typography variant="h3" className={classes.title}>
                  {slide.title}
                </Typography>

                <Typography
                  variant="body1"
                  component="div"
                  className={classes.description}
                >
                  {slide.description}
                </Typography>
              </div>
            </div>
          </FadeTransition>
        </SwitchTransition>
      </div>
      <Dots setIndex={_setIndex} index={index} />
      <Arrows setIndex={_setIndex} index={index} total={slides.length} />
    </div>
  )
}

const useStyles = makeStyles(
  theme => ({
    "@global .fade-enter": {
      opacity: "0.01",
      position: "absolute",
      top: 0
    },
    "@global .fade-enter-active": {
      opacity: "1",
      transition: `opacity ${SLIDER_CONFIG.enter}ms ease-in`
    },
    "@global .fade-exit": {
      opacity: "1",
      position: "absolute",
      top: 0
    },
    "@global .fade-exit-active": {
      opacity: "0.01",
      transition: `opacity ${SLIDER_CONFIG.exit}ms ease-in`
    },
    root: {
      "& a": {
        textDecoration: "underline"
      },
      "& p, & ul": {
        fontSize: "14px",
        margin: "8px 0",
        [theme.breakpoints.up("md")]: {
          fontSize: "16px"
        }
      },
      "& ul": {
        paddingLeft: "24px",
        [theme.breakpoints.up("md")]: {
          paddingLeft: "42px"
        }
      }
    },
    animated: {
      position: "relative"
    },
    inner: {
      [theme.breakpoints.up("md")]: {
        display: "grid",
        gridTemplateColumns: "55% 45%",
        gridTemplateAreas: '"text image"'
      }
    },
    text_block: {
      paddingRight: "16px",
      gridArea: "text"
    },
    title: {
      color: theme.palessit.whiteSemi,
      marginTop: "16px",
      marginBottom: "16px",
      fontSize: "24px",
      [theme.breakpoints.up("md")]: {
        fontSize: "46px",
        fontWeight: "300",
        marginTop: "0",
        marginBottom: "32px"
      }
    },
    description: {
      color: theme.palessit.whiteSemi,
      lineHeight: "24px",
      [theme.breakpoints.up("md")]: {
        lineHeight: "26px"
      }
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
      gridArea: "image",
      display: "block",
      margin: "0 auto",
      height: "140px",
      [theme.breakpoints.up("md")]: {
        height: "auto"
      }
    }
  }),
  { name: "HeroSlider" }
)
