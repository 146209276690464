import { TRouteConfig } from "chyk"
import { FC } from "react"
import { NotFound } from "../common/not-found"
import { HiringPage } from "../hiring/hiring-page"
import { AppLayout } from "./app-layout"
import { HomePage } from "./home-page"
import { nav } from "./nav"
import { TeamPage } from "./Team"
import { ChykPage } from "./chyk"

// workaround for badly typed jss 10 alpha
export const SheetsRegistry: any = require("jss").SheetsRegistry

export const routes: TRouteConfig[] = [
  {
    component: AppLayout as any,
    routes: [
      {
        path: nav.home.path,
        exact: true,
        component: HomePage
      },
      { path: nav.hiring.path, component: HiringPage },
      { path: nav.team.path, component: TeamPage },
      { path: nav.chyk.path, component: ChykPage },
      {
        component: NotFound as FC,
        loadData: async ({ chyk }) => chyk.set404()
      }
    ]
  }
]
