import { makeStyles } from "@material-ui/styles"
import React from "react"
import { Inner } from "../common/inner"
import { Wrapper } from "../common/wrapper"

const useStyles = makeStyles(
  {
    root: {
      textAlign: "center",
      fontSize: "1.3em",
      ["@media (min-width: 460px)" as any]: {
        fontSize: "1.5em"
      }
    },
    text: {
      textAlign: "left"
    }
  },
  { name: "HiringTeam" }
)

export const HiringTeam = () => {
  const classes = useStyles()
  return (
    <Wrapper className={classes.root}>
      <Inner>
        <h3>Team</h3>
        <p className={classes.text}>
          We are passionate people pushing forward imagination with technology.
          <br />
          We have different hobbies from engineering airplanes to music writing.
        </p>
        <h3>Company</h3>
        <p className={classes.text}>
          We are working with pretty modern and interesting tech as React,
          TypeScript, Kubernetes...
          <br />
          Our clients are mid to big customers from Germany, USA, GB and Russia.
        </p>
        <h3>Philosophy</h3>
        <p className={classes.text}>
          Working fast, resolving issues and aiming the result.
          <br />
          We avoid bureaucracy.
        </p>
      </Inner>
    </Wrapper>
  )
}
