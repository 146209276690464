import { createMuiTheme } from "@material-ui/core/styles"

export const IMG_PREFIX = "https://res.cloudinary.com/palessit/image/upload"

export const IMG_SUFFIX = "/palessit"

export type TPalessitTheme = {
  ultraGradient: React.CSSProperties["backgroundColor"]
  ultraMid: React.CSSProperties["color"]
  whiteSemi: React.CSSProperties["color"]
  darkBg: React.CSSProperties["color"]
  lightBg: React.CSSProperties["color"]
  black: React.CSSProperties["color"]
  innerMaxWidth: React.CSSProperties["width"]
  primaryBlue: React.CSSProperties["color"]
  blockPadding: React.CSSProperties["padding"]
  blockPaddingMobile: React.CSSProperties["padding"]
  cardShadow: React.CSSProperties["boxShadow"]
}

const palessitTheme = {
  ultraGradient: "linear-gradient(to right, #36567c 0%,#5f4b8b 100%)",
  ultraMid: "#4a5281",
  whiteSemi: "rgba(255,255,255,0.87)",
  darkBg: "#5F4B8B",
  lightBg: "#f3f2f7",
  black: "rgba(74,82,129,0.87)",
  innerMaxWidth: "900px",
  primaryBlue: "#A7D0EF",
  blockPadding: "2rem",
  blockPaddingMobile: "1rem",
  cardShadow:
    "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
}

export const theme = createMuiTheme({
  palessit: palessitTheme
})
