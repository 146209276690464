import { Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React from "react"
import { AtvImg } from "../common/AppleTvCard"
import { Wrapper } from "../common/wrapper"
import { IMG_PREFIX, IMG_SUFFIX } from "./theme"

const useStyles = makeStyles(
  (theme: Theme) => ({
    inner: {
      maxWidth: "900px",
      margin: "0 auto",
      display: "grid",
      gridGap: "2rem",
      gridAutoColumns: "1fr",
      justifyItems: "center",
      justifyContent: "center",
      gridTemplateColumns: "repeat(auto-fit,120px)",
      [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "repeat(auto-fit,200px)"
      }
      // [theme.breakpoints.up("md")]: {
      //   gridTemplateColumns: "repeat(auto-fit,200px)"
      // }
    },
    card: {
      width: "120px",
      height: "90px",
      [theme.breakpoints.up("sm")]: {
        width: "200px",
        height: "150px"
      }
      // [theme.breakpoints.up("md")]: {
      //   width: "200px",
      //   height: "150px"
      // },
    }
  }),
  { name: "WhatWeDo" }
)

// const overlayStyle = {
//   backgroundColor: "rgba(56, 72, 93, 0.75)",
//   width: "114%",
//   height: "114%",
//   top: "-7%",
//   left: "-7%"
// }

export const WhatWeDo: React.FC = () => {
  const classes = useStyles()
  return (
    <Wrapper id="what-we-do">
      <h2>What we do</h2>
      <p>Everything around web. Using its abilities to empower businesses.</p>
      <div className={classes.inner}>
        <Large />
        <Admin />
        <Small />
        <Site />
      </div>
    </Wrapper>
  )
}

const Large = () => {
  const classes = useStyles()
  return (
    <AtvImg
      layers={[
        `${IMG_PREFIX}${IMG_SUFFIX}/services/big-webapp.svg`,
        `${IMG_PREFIX}${IMG_SUFFIX}/services/big-webapp-buttons.svg`,
        `${IMG_PREFIX}${IMG_SUFFIX}/services/big-webapp-cards.svg`,
        `${IMG_PREFIX}${IMG_SUFFIX}/services/big-webapp-cards.svg`
        // <TextLayer text="Large web apps" />
      ]}
      className={classes.card}
    />
  )
}
const Small = () => {
  const classes = useStyles()
  return (
    <AtvImg
      layers={[
        `${IMG_PREFIX}${IMG_SUFFIX}/services/small-webapp.svg`,
        `${IMG_PREFIX}${IMG_SUFFIX}/services/small-webapp-buttons.svg`,
        `${IMG_PREFIX}${IMG_SUFFIX}/services/small-webapp-text.svg`
        // <TextLayer text="Small web apps" />
      ]}
      className={classes.card}
    />
  )
}
const Admin = () => {
  const classes = useStyles()
  return (
    <AtvImg
      layers={[
        `${IMG_PREFIX}${IMG_SUFFIX}/services/admin-webapp.svg`,
        `${IMG_PREFIX}${IMG_SUFFIX}/services/admin-webapp-cards.svg`,
        `${IMG_PREFIX}${IMG_SUFFIX}/services/admin-webapp-text.svg`
        // <TextLayer text="Dashboards" style={overlayStyle} />
      ]}
      className={classes.card}
    />
  )
}
const Site = () => {
  const classes = useStyles()
  return (
    <AtvImg
      layers={[
        `${IMG_PREFIX}${IMG_SUFFIX}/services/website.svg`,
        `${IMG_PREFIX}${IMG_SUFFIX}/services/website-img.svg`,
        `${IMG_PREFIX}${IMG_SUFFIX}/services/website-text.svg`
        // <TextLayer text="Websites" style={overlayStyle} />
      ]}
      className={classes.card}
    />
  )
}
