import { Button, makeStyles } from "@material-ui/core"
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons"
import clsx from "clsx"
import React, { FC } from "react"

export const Arrows: FC<{
  index: number
  total: number
  setIndex: (i: number) => void
}> = ({ index, total, setIndex }) => {
  const classes = useStyles()
  return (
    <>
      <Button
        className={clsx(classes.arrow, classes.arrow_left)}
        disabled={index === 0}
        onClick={() => setIndex(index - 1)}
      >
        <ArrowBackIos fontSize="inherit" className={classes.arrow_icon} />
      </Button>
      <Button
        className={clsx(classes.arrow, classes.arrow_right)}
        disabled={index + 1 === total}
        onClick={() => setIndex(index + 1)}
      >
        <ArrowForwardIos fontSize="inherit" className={classes.arrow_icon} />
      </Button>
    </>
  )
}

const useStyles = makeStyles(
  theme => ({
    arrow: {
      position: "absolute",
      transform: "translate(-50%,-50%)",
      color: theme.palessit.whiteSemi,
      borderRadius: "50%",
      opacity: 0.5,
      padding: 0,
      minWidth: 0,
      fontSize: 24,
      width: 50,
      height: 50,
      top: 160 + 36,
      [theme.breakpoints.up("sm")]: {
        top: 160 + 72
      },
      [theme.breakpoints.up("md")]: {
        top: 130 + 72,
        fontSize: 42,
        width: 60,
        height: 60
      },
      [theme.breakpoints.up("lg")]: {
        top: "50%",
        width: 200,
        height: 200
      },
      "&:hover": {
        opacity: 1,
        cursor: "pointer"
      }
    },
    arrow_left: {
      left: 20,
      [theme.breakpoints.up("md")]: {
        left: 10
      },
      [theme.breakpoints.up("lg")]: {
        left: -90
      }
    },
    arrow_right: {
      right: -30,
      [theme.breakpoints.up("md")]: {
        right: -50
      },
      [theme.breakpoints.up("lg")]: {
        right: -290
      }
    },
    arrow_icon: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)"
    },
    arrow_disabled: {}
  }),
  { name: "Arrows" }
)
