import { makeStyles } from "@material-ui/core"
import React, { FC } from "react"
import { nav } from "./nav"
import { TopNavLink } from "./top-nav-link"
import { IMG_PREFIX, IMG_SUFFIX } from "./theme"

export const TopNav: FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.space}>
        <TopNavLink to={"/"} style={{ padding: 0 }}>
          <img
            className={classes.logo}
            src={`${IMG_PREFIX}/v1579434978${IMG_SUFFIX}/img/logo-header-upper`}
          />
        </TopNavLink>
      </div>
      <div>
        <TopNavLink to={nav.home.anchors.tech.anchor}>Tech</TopNavLink>
        <TopNavLink to={nav.team.path}>Team</TopNavLink>
        <TopNavLink to={nav.home.anchors.contact.anchor}>Contact</TopNavLink>
        <TopNavLink to={nav.hiring.path}>Hiring</TopNavLink>
      </div>
    </div>
  )
}
const useStyles = makeStyles(
  theme => ({
    root: {
      width: "100%",
      padding: "0 8px",
      position: "fixed",
      top: 0,
      background: "#4b5282cc",
      backdropFilter: "blur(2px)",
      zIndex: 20,
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      display: "flex",
      height: "44px",
      [theme.breakpoints.up("sm")]: {
        height: "60px"
      }
    },
    space: {
      flex: "1 1",
      display: "flex",
      alignItems: "center"
    },
    logo: {
      display: "block",
      height: "12px",
      [theme.breakpoints.up("sm")]: {
        height: "25px"
      }
    }
  }),
  { name: "TopNav" }
)
