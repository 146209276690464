import { Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import React from "react"

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      maxWidth: theme.palessit.innerMaxWidth,
      margin: "0 auto",
      display: "grid",
      justifyItems: "center",
      gridColumnGap: "16px",
      gridRowGap: "16px",
      gridTemplateColumns: "repeat( auto-fit, minmax(280px, 1fr) )",
      [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "repeat( auto-fit, minmax(320px, 1fr) )"
      }
    }
  }),
  { name: "CardsContainer" }
)

type TCardsContainerProps = {} & React.HTMLAttributes<HTMLDivElement>
export const CardsContainer: React.FC<TCardsContainerProps> = props => {
  const classes = useStyles()
  const { className, ...rest } = props

  return <div className={clsx(classes.root, className)} {...rest} />
}
