import { Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import React, { FC, ImgHTMLAttributes } from "react"
import { IMG_PREFIX, IMG_SUFFIX } from "./theme"

export enum IconName {
  ts = "ts",
  react = "react-fit",
  docker = "docker",
  wordpress = "wordpress",
  cloudflare = "cloudflare",
  pagespeed = "pagespeedtest",
  webpagetest = "webpagetest",
  seo = "seo",
  aws = "aws",
  lambda = "lambda",
  nodejs = "nodejs",
  jest = "jest",
  webpack = "webpack",
  materialUi = "materialUI",
  cdn = "cdn"
}
export const tooltip = {
  [IconName.ts]: "TypeScript",
  [IconName.react]: "React",
  [IconName.docker]: "Docker",
  [IconName.wordpress]: "Wordpress",
  [IconName.cloudflare]: "Cloudflare",
  [IconName.pagespeed]: "100% PageSpeed Insights",
  [IconName.webpagetest]: "All A Webpagetest",
  [IconName.seo]: "SEO",
  [IconName.aws]: "Amazon Web Services",
  [IconName.lambda]: "AWS Lambda",
  [IconName.nodejs]: "NodeJS",
  [IconName.jest]: "Jest",
  [IconName.webpack]: "Webpack",
  [IconName.materialUi]: "Material-UI",
  [IconName.cdn]: "CDN"
}

export const TechIcon: FC<ImgHTMLAttributes<any> & { icon: IconName }> = ({
  icon,
  ...rest
}) => {
  return (
    <img
      src={`${IMG_PREFIX}/v1567596067${IMG_SUFFIX}/stack/${icon}`}
      {...rest}
    />
  )
}

type TIconV2Props = {
  icon: IconName
  tooltip: string
  link?: string
} & React.HTMLAttributes<HTMLDivElement>

export const Icon: FC<TIconV2Props> = ({ icon, className, tooltip, link }) => {
  const classes = useIconStyleStyles()
  return (
    <Tooltip title={tooltip} placement="top">
      <a href={link} target="_blank">
        <TechIcon className={clsx(classes.icon, className)} icon={icon} />
      </a>
    </Tooltip>
  )
}

const useIconStyleStyles = makeStyles(
  {
    icon: {
      height: "22px",
      width: "22px",
      objectFit: "contain",
      margin: "0 4px",
      transition: "0.3s",
      filter: "grayscale(90%) opacity(70%)",
      "&:hover": {
        filter: "grayscale(0%)",
        transform: "scale(1.2)"
      },
      ["@media (min-width: 460px)" as any]: {
        height: "25px",
        width: "25px",
        margin: "0 5px"
      }
    }
  },
  { name: "Icon" }
)
