import { DataRoutes, useChyk, TRouteComponentProps } from "chyk"
import React, { FC } from "react"
import { NotFound } from "../common/not-found"
import { ContactFooter } from "./ContactFooter"
import { TopNav } from "./top-nav"

type TAppLayoutProps = TRouteComponentProps<{}>
export const AppLayout: FC<TAppLayoutProps> = ({ route }) => {
  const chyk = useChyk()
  return (
    <div>
      <TopNav />
      <div>
        {chyk.is404 ? (
          <NotFound />
        ) : (
          route.routes && <DataRoutes routes={route.routes} />
        )}
      </div>
      <ContactFooter />
    </div>
  )
}
