import { Button, makeStyles, Omit } from "@material-ui/core"
import React from "react"
import { Link, LinkProps } from "react-router-dom"

type TTopNavLinkProps = {} & Omit<LinkProps, "color">
export const TopNavLink: React.FC<TTopNavLinkProps> = props => {
  const classes = useStyles()
  return (
    <Button
      component={Link as any}
      size="large"
      className={classes.root}
      {...props}
    />
  )
}
const useStyles = makeStyles(
  theme => ({
    root: {
      color: "#fff !important",
      height: "100%",
      borderRadius: "0 !important",
      fontSize: "12px",
      padding: "8px 8px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "16px",
        padding: "8px 11px"
      }
    }
  }),
  { name: "TopNavLink" }
)
