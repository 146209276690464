import React, { FC } from "react"
import { ThemeProvider } from "@material-ui/styles"
import { theme } from "./theme"
import { CssBaseline } from "@material-ui/core"

export const LayoutRoot: FC = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </>
  )
}
