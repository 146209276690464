import React, { FC } from "react"
import { CardsContainer } from "../common/cards-container"
import { Wrapper } from "../common/wrapper"
import { Card } from "./Card"
import { nav } from "./nav"
import { IMG_PREFIX, IMG_SUFFIX } from "./theme"

type TTeamPageProps = {
  imgSrc: string
}

export const TeamPage: FC = () => (
  <div style={{ paddingTop: "100px" }}>
    <Team />
  </div>
)

const TeamImg: FC<TTeamPageProps> = ({ imgSrc }) => {
  return (
    <>
      <source
        srcSet={`${IMG_PREFIX}/w_320,f_webp${IMG_SUFFIX}/${imgSrc} 1170w,
              ${IMG_PREFIX}/w_220,f_webp${IMG_SUFFIX}/${imgSrc} 750w`}
        type="image/webp"
      />
      <source
        srcSet={`${IMG_PREFIX}/w_320,f_jpg${IMG_SUFFIX}/${imgSrc} 1170w,
              ${IMG_PREFIX}/w_220,f_jpg${IMG_SUFFIX}/${imgSrc} 750w`}
        type="image/jpg"
      />
    </>
  )
}

export const Team: FC = () => {
  return (
    <Wrapper id={nav.home.anchors.team.id}>
      <h2>Team</h2>
      <p>There are ongoing structural changes in a company.</p>
    </Wrapper>
  )
}

export const Team_Original: FC = () => {
  return (
    <Wrapper id={nav.home.anchors.team.id}>
      <h2>Team</h2>
      <p>
        Passionate people. We're happy to implement new things, as well as to
        integrate with teams.
      </p>
      <CardsContainer>
        <Card
          alignRight
          imgSrc="/team/alexi.jpg"
          heading="Alexi"
          description={[
            "CEO, Co-founder, fullstack.",
            "\n",
            "Problem solving.",
          ]}
          source={<TeamImg imgSrc="/team/alexi.jpg" />}
        />
        <Card
          imgSrc="/team/dima-square.jpg"
          heading="Dzmitry"
          description={["COO, Co-founder, fullstack.", "\n", "Using science."]}
          source={<TeamImg imgSrc="/team/dima-square.jpg" />}
        />
        <Card
          alignRight
          imgSrc="/team/sasha-square.jpg"
          heading="Alexander"
          description={["Frontend, React, SEO.", "\n", "Rollerskating."]}
          source={<TeamImg imgSrc="/team/sasha-square.jpg" />}
        />
        <Card
          imgSrc="/team/lesha-square.jpg"
          heading="Alexey"
          description={[
            "Backend, Nodejs, Kubernetes.",
            "\n",
            "Being cool and serious.",
          ]}
          source={<TeamImg imgSrc="/team/lesha-square.jpg" />}
        />
        {/* <Card alignRight imgSrc="/team/serg.jpg" title="Serg" text="DevOps" /> */}
        {/* <Card imgSrc="/team/kenny.jpg" title="Kenny" text="Design, UI/UX" /> */}
        <Card
          alignRight
          imgSrc="/team/slava.jpg"
          heading="Slava"
          description={[
            "Frontend, WordPress.",
            "\n",
            "Unmanned aerial vehicles.",
          ]}
          source={<TeamImg imgSrc="/team/slava.jpg" />}
        />
        <Card
          imgSrc="/team/alexa.jpg"
          heading="Alexa"
          description={["Graphic design.", "\n", "Imagine and embody."]}
          source={<TeamImg imgSrc="/team/alexa.jpg" />}
        />
        <Card
          alignRight
          imgSrc="/team/alexl.jpg"
          heading="Alexey"
          description={["WordPress lead developer.", "\n", "PHP and memes."]}
          source={<TeamImg imgSrc="/team/alexl.jpg" />}
        />
        <Card
          imgSrc="/team/sergey.jpg"
          heading="Sergej"
          description={["Backend, Nodejs.", "\n", "Engineering and cats"]}
          source={<TeamImg imgSrc="/team/sergey-new.jpg" />}
        />
        <Card
          alignRight
          imgSrc="/team/davidson.jpg"
          heading="Davidson"
          description={["Frontend engineer.", "\n", "Curly geek."]}
          source={<TeamImg imgSrc="/team/davidson.jpg" />}
        />
        <Card
          imgSrc="/team/gris.png"
          heading="Gris"
          description={["UX/UI Designer.", "\n", "Discovering new worlds."]}
          source={<TeamImg imgSrc="/team/gris.png" />}
        />
        <Card
          alignRight
          imgSrc="/team/jenia.png"
          heading="Eugeniy"
          description={["Project Manager.", "\n", "For the Horde!"]}
          source={<TeamImg imgSrc="/team/jenia.png" />}
        />
        <Card
          imgSrc="/team/olga.jpg"
          heading="Olya"
          description={["Chief Accountant.", "\n", "Travels and emotions."]}
          source={<TeamImg imgSrc="/team/olga.jpg" />}
        />
        {/* <Card // Заглушка
          imgSrc="/team/new-worker.jpg"
          heading="Your name"
          description={["Your skills.", "\n", "Nice person."]}
          source={<TeamImg imgSrc="/team/new-worker.jpg" />}
        /> */}
      </CardsContainer>
    </Wrapper>
  )
}
