import { Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import React from "react"

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      maxWidth: theme.palessit.innerMaxWidth,
      margin: "0 auto"
    }
  }),
  { name: "Inner" }
)

type TInnerProps = {} & React.HTMLAttributes<HTMLDivElement>
export const Inner: React.FC<TInnerProps> = props => {
  const classes = useStyles()
  const { className, ...rest } = props

  return <div className={clsx(classes.root, className)} {...rest} />
}
