import { Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React from "react"
import { Wrapper } from "../common/wrapper"
import { Card } from "./Card"
import { nav } from "./nav"

const usesStyles = makeStyles(
  (theme: Theme) => ({
    inner: {
      maxWidth: theme.palessit.innerMaxWidth,
      margin: "0 auto",
      display: "grid",
      justifyItems: "center",
      gridColumnGap: "16px",
      gridRowGap: "16px",
      gridTemplateColumns: "repeat( auto-fit, minmax(280px, 1fr) )",
      [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "repeat( auto-fit, minmax(320px, 1fr) )"
      }
    }
  }),
  { name: "OurStack" }
)

export const OurStack: React.FC = () => {
  const classes = usesStyles({})
  return (
    <Wrapper variant="primary" id={nav.home.anchors.tech.id}>
      <h2>Stack</h2>
      <p>Modern tech stack unlocks stable AND fast development.</p>
      <div className={classes.inner}>
        <Card
          alignRight
          imgSrc="/stack/react-fit.svg"
          heading="React.js"
          description="Have revolutionized frontend development. Using component-based-architecture makes it painless to create interactive UIs. "
        />
        <Card
          imgSrc="/stack/ts.svg"
          heading="TypeScript"
          description="Allows static checking and code refactoring when developing JavaScript applications. Critical for large apps."
        />
        <Card
          alignRight
          imgSrc="/stack/graphql.svg"
          heading="GraphQL"
          description="Query language for API, and a server-side runtime for executing queries by using a type system."
        />
        <Card
          imgSrc="/stack/prisma3.svg"
          heading="Prisma"
          description="Simplified & type-safe database access. Declarative migrations & data modeling. Powerful & visual data management"
        />
        <Card
          alignRight
          imgSrc="/stack/nodejs-inverted.svg"
          heading="Node.js"
          description="Is a server runtime for JavaScript. Means we write TypeScript on both: backend and frontend."
        />
        <Card
          imgSrc="/stack/docker.svg"
          heading="Docker"
          description="Is an open platform to create, deploy and manage virtualized application containers."
        />
        <Card
          alignRight
          imgSrc="/stack/k8s.svg"
          heading="Kubernetes"
          description="Production-Grade Container Orchestration. Automated container deployment, scaling, and management."
        />
        <Card
          imgSrc="/stack/logos.svg"
          heading="Ecosystem"
          description="Yarn, Webpack, PostgreSQL, MongoDB, Git, Jest, Mobx, MST, PostCSS and others..."
        />
      </div>
    </Wrapper>
  )
}
