import React, { FC } from "react"
import { Wrapper } from "../common/wrapper"
import { makeStyles } from "@material-ui/styles"
import { Inner } from "../common/inner"

export const ChykPage: FC = () => {
  const classes = useStyles()
  return (
    <div>
      <Wrapper variant="primary" className={classes.top}>
        <h1>
          Chyk - microframework to build universal SPAs on TypeScript, React.
        </h1>
      </Wrapper>

      <Wrapper>
        <Inner>
          <article className={classes.article}>
            <p>
              2020 is coming and more SPAs will be written than ever. After all
              frontend fun we still have questions in frontend-opsy topics. How
              about these features:
            </p>
            <ul>
              <li>
                SSR support and passing SSR data to the browser for immediate
                hydration
              </li>
              <li>
                preload all route data before rendering, including promise.all
                for route matches
              </li>
              <li>code splitting via async components (lazy load)</li>
              <li>404 and other status pages</li>
              <li>aborting data load if we’re switching route</li>
              <li>dependency injection in components and in data loaders</li>
            </ul>
            <p>
              Working on these questions sometimes leads to a library. So here’s
              Chyk - microframework to build universal SPAs with React.
            </p>

            <p>
              The library is written in TypeScript and aiming to help with
              features described above. Chyk is based on react-router-config
              approach for routes config. It allows to statically analyze
              config, preload all needed data and inject it to the route
              components.
            </p>
            <p>
              Check{" "}
              <a href="https://www.youtube.com/channel/UCl5a2QKSgz-tpdZrOERPpaQ?view_as=subscriber">
                screencast
              </a>{" "}
              and <a href="https://github.com/palessit/chyk">examples</a>.
              <br />
              Thanks!
            </p>
          </article>
        </Inner>
      </Wrapper>
    </div>
  )
}

const useStyles = makeStyles(
  () => ({
    top: {
      height: "200px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "60px 0 0 0"
    },
    article: {
      textAlign: "left"
    }
  }),
  { name: "Chyk" }
)
