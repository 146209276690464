import { makeStyles } from "@material-ui/styles"
import React from "react"
import { Card } from "../app/Card"
import { Inner } from "../common/inner"
import { Wrapper } from "../common/wrapper"

const useJobsStyles = makeStyles(
  {
    card: {
      maxWidth: "none",
      textAlign: "left",
      gridTemplateColumns: "auto",
      gridTemplateRows: "auto",
      // gridTemplateAreas: '"img title" "img description"',
      ["@media (min-width: 460px)" as any]: {
        gridTemplateRows: "36px auto",
        gridTemplateColumns: "160px auto"
      }
    }
  },
  { name: "Jobs" }
)

export const Jobs = () => {
  const classes = useJobsStyles()
  return (
    <Wrapper>
      <Inner>
        <Card
          className={classes.card}
          imgSrc="/img/developer-2.svg"
          heading="TypeScript/JavaScript fullstack developer"
          description={<TsDev />}
        />
        <br />
        <Card
          className={classes.card}
          imgSrc="/img/developer.svg"
          heading="WordPress developer"
          description={<WpDev />}
        />
        <br />
        <Card
          className={classes.card}
          imgSrc="/img/project-manager.svg"
          heading="Project manager"
          description={<Project />}
        />
        <br />
        <Card
          className={classes.card}
          imgSrc="/img/product-manager.svg"
          heading="Product manager"
          description={<Product />}
        />
      </Inner>
    </Wrapper>
  )
}

const WpDev: React.FC = () => (
  <>
    <h4>Responsibilities</h4>
    <ul>
      <li>WordPress development branch of the company</li>
      <li>Custom plugins and themes development</li>
      <li>Product work on current and new projects</li>
      <li>Audit sites for bottlenecks and problem areas</li>
      <li>Security in WordPress</li>
      <li>Work with both standard hosting and cloud</li>
      <li>WordPress-Docker setups</li>
    </ul>
    <h4>Requirements</h4>
    <ul>
      <li>Confident knowledge of WordPress, PHP and MySQL</li>
      <li>Ability to conduct WP development in git</li>
      <li>
        Experience custom development under WordPress: plugins, themes, custom
        API points, wordpress optimization and profiling
      </li>
      <li>English minimum - reading documentation</li>
      <li>Pros: Docker, cloud platforms, understanding pagespeed insights</li>
    </ul>
  </>
)

const TsDev: React.FC = () => (
  <>
    <h4>Responsibilities</h4>
    <ul>
      <li>
        Working with the modern tech stack (TypeScript, Nodejs, React,
        Material-UI)
      </li>
      <li>Developing Single Page Applications</li>
      <li>Developing backend on a Koajs</li>
      <li>Delivering reliable software with the stable architecture designs</li>
      <li>Audit sites for bottlenecks and problem areas</li>
    </ul>
    <h4>Requirements</h4>
    <ul>
      <li>Solid knowledge of the modern web development practices</li>
      <li>Expertise in building single page applications on React, Nodejs</li>
      <li>Pros: Docker, cloud platforms, understanding pagespeed insights</li>
      <li>English minimum - reading documentation</li>
    </ul>
  </>
)

const Project: React.FC = () => (
  <>
    <h4>Responsibilities</h4>
    <ul>
      <li>Management of the outsourcing projects</li>
      <li>Communication with customers from around world</li>
      <li>Periodic checking ongoing projects</li>
      <li>Task management</li>
      <li>Reports to the CEO</li>
    </ul>
    <h4>Requirements</h4>
    <ul>
      <li>Experience and strong knowledge of the project management in IT</li>
      <li>Base understanding of the webdevelopment</li>
      <li>High communication skills</li>
      <li>Speaking English</li>
    </ul>
  </>
)

const Product: React.FC = () => (
  <>
    <h4>Responsibilities</h4>
    <ul>
      <li>Development of the outsource products</li>
      <li>Collaboration with the customers, developers, designers</li>
      <li>Following tendencies</li>
    </ul>
    <h4>Requirements</h4>
    <ul>
      <li>Experience in the gambling industry</li>
      <li>High communication skills</li>
      <li>Speaking English</li>
    </ul>
  </>
)

// const Designer: React.FC = () => (
//   <>
//     <h4>Requirements</h4>
//     <ul>
//       <li>Familiar with design patterns, the latest web design trends</li>
//       <li>Enjoy working closely with a development team and customers</li>
//       <li>Ready to a fast iteration processes</li>
//     </ul>
//   </>
// )
