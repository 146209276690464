import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React, { FC, useEffect, useRef } from "react"
import { SLIDER_CONFIG, slides } from "./hero-slides"

export const Dots: FC<{ index: number; setIndex: (i: number) => void }> = ({
  index,
  setIndex
}) => {
  const classes = useStyles()
  return (
    <div className={classes.dots}>
      {slides.map((_s, i) =>
        i === index ? (
          <ActiveDot key={i} onClick={() => setIndex(i)} />
        ) : (
          <Dot key={i} onClick={() => setIndex(i)} />
        )
      )}
    </div>
  )
}

type TDotProps = {
  onClick: () => void
}
const Dot: FC<TDotProps> = ({ onClick }) => {
  const classes = useStyles()
  return (
    <div
      className={clsx(classes.dot, classes.dot_inactive)}
      onClick={onClick}
    />
  )
}

const ActiveDot: FC<TDotProps> = ({ onClick }) => {
  const classes = useStyles()

  return (
    <div className={classes.dot} onClick={onClick}>
      <PerfDot />
    </div>
  )
}

const l = 43.98
const PerfDot: FC = () => {
  const circleRef = useRef<SVGCircleElement>(null)

  useEffect(() => {
    let start: number = 0
    let unmounted: boolean = false
    const step: FrameRequestCallback = (raf_timestamp: number) => {
      if (unmounted) return
      if (start === 0) start = raf_timestamp
      const progress =
        ((raf_timestamp - start) / SLIDER_CONFIG.transition) * 100

      if (circleRef.current) {
        const circle_progress = (progress / 100) * l
        circleRef.current.setAttribute(
          "stroke-dasharray",
          `${circle_progress} ${l - circle_progress}`
        )
      }
      if (progress < SLIDER_CONFIG.transition)
        window.requestAnimationFrame(step)
    }
    window.requestAnimationFrame(step)
    return () => {
      unmounted = true
    }
  }, [])

  const classes = useStyles()
  return (
    <svg className={classes.progress_root} viewBox="0 0 16 16">
      <circle
        ref={circleRef}
        className={classes.progress_ring}
        fill="transparent"
        cx="8"
        cy="8"
        r="7"
        strokeWidth="2"
        strokeDasharray="0 43.98" // 43.98
        strokeDashoffset="10.9956"
      />
    </svg>
  )
}

const useStyles = makeStyles(
  theme => ({
    dots: {
      position: "absolute",
      bottom: "40px",
      left: "50%",
      transform: "translateX(-50%)",
      display: "flex"
    },
    dot: {
      position: "relative",
      width: "12px",
      height: "12px",
      borderRadius: "50%",
      background: "gray",
      margin: "0 4px",
      opacity: "0.7"
    },
    dot_active: {
      background: theme.palessit.whiteSemi
    },
    dot_inactive: {
      cursor: "pointer"
    },
    progress_root: {
      color: theme.palessit.whiteSemi,
      position: "absolute",
      top: -2,
      left: -2,
      width: "16px",
      height: "16px",
      strokeLinecap: "round"
    },
    progress_ring: {
      stroke: theme.palessit.whiteSemi
    }
  }),
  { name: "Dot" }
)
