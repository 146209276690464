import { makeStyles } from "@material-ui/styles"
import React from "react"
import { Wrapper } from "../common/wrapper"

const useStyles = makeStyles(
  () => ({
    root: {
      height: "200px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "60px 0 0 0"
    }
  }),
  { name: "HiringHero" }
)

export const HiringHero = () => {
  const classes = useStyles()
  return (
    <Wrapper variant="primary" className={classes.root}>
      <h1>We are hiring, friends!</h1>
    </Wrapper>
  )
}
