import { Button, makeStyles, Typography } from "@material-ui/core"
import React, { FC } from "react"
import { Link } from "react-router-dom"
import { Inner } from "../../common/inner"
import { HeroSlider } from "./hero-slider"

export const Hero: FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <Inner className={classes.inner}>
        <Typography variant="h1" className={classes.title}>
          Palessit - web dev agency
        </Typography>
        <HeroSlider />
      </Inner>
      <Inner className={classes.buttons}>
        <Button
          variant="outlined"
          color="inherit"
          className={classes.contact}
          href="mailto:alexi@palessit.com"
        >
          Contact us
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          className={classes.next}
          component={Link as any}
          to="/#what-we-do"
        >
          Next &darr;
        </Button>
      </Inner>
    </div>
  )
}

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      height: "100vh",
      width: "100vw",
      maxWidth: "100%",
      position: "relative",
      background: theme.palessit.ultraGradient,
      padding: "0 1rem",
      "@media (orientation: landscape)": {
        minHeight: "540px"
      }
    },
    inner: {
      position: "relative",
      height: "100%",
      paddingBottom: "64px",
      paddingTop: "60px",
      [theme.breakpoints.up("sm")]: {
        paddingTop: "13vh"
      },
      "@media (orientation: landscape)": {
        paddingTop: "90px"
      }
    },
    title: {
      textAlign: "center",
      color: theme.palessit.whiteSemi,
      marginBottom: "8px",
      marginTop: "36px",
      fontSize: "28px",
      [theme.breakpoints.up("sm")]: {
        marginBottom: "9vh",
        marginTop: "72px"
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "64px"
      }
    },
    buttons: {
      position: "relative"
    },
    contact: {
      color: theme.palessit.whiteSemi,
      position: "absolute",
      bottom: "24px",
      left: 0,
      width: "126px"
    },
    next: {
      color: theme.palessit.whiteSemi,
      position: "absolute",
      bottom: "24px",
      right: 0,
      width: "126px"
    }
  }),
  { name: "Hero" }
)
