import React, { FC } from "react"
import { Helmet } from "react-helmet"

export const NotFound: FC = () => {
  const text = "404 Page not found"
  return (
    <>
      <Helmet>
        <title>{text}</title>
        <meta name="description" content={text} />
      </Helmet>
      <h1 style={{ textAlign: "center", margin: "100px auto" }}>{text}</h1>
    </>
  )
}
