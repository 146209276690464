import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React, { FC } from "react"
import { Inner } from "../common/inner"
import { Wrapper } from "../common/wrapper"

export const Offers: React.FC = () => (
  <Wrapper variant="light">
    {/* <h2>Offers, cases and examples</h2>
    <br /> */}
    <Grid container>
      <GridItem title="React Single Page Applications">
        <li>React SPA development, Server Render (SSR) for SEO</li>
        <li>Mobile first and Progressive Web Apps (PWA)</li>
        <li>Pagespeed and Webpagetest green scores</li>
      </GridItem>

      <GridItem title="Nodejs, AWS Lambda, Cloudflare workers">
        <li>HTTP API services</li>
        <li>Realtime applications</li>
        <li>Serverless and microservices develoment</li>
      </GridItem>

      <GridItem title="WordPress custom development">
        <li>Custom hight standard themes</li>
        <li>Plugins and shortcodes</li>
        <li>Integrations, monitoring and administration</li>
      </GridItem>

      <GridItem title="Infrastructure improvements">
        <li>Migrations to Docker, Kubernetes, Cloudworkers</li>
        <li>Migration to cloud, scaling and security</li>
        <li>Workflow setup (backups, CI/CD, staging)</li>
      </GridItem>

      <GridItem title="SEO and optimisations">
        <li>Improving scores in Pagespeed and Webpagetest</li>
        <li>Opengraph, Schema, analytics, robots, sitemap..</li>
        <li>SEO</li>
      </GridItem>

      <GridItem title="UI/UX and design">
        <li>UI and UX (re)designs</li>
        <li>Branding</li>
        <li>Vector and pixel graphics</li>
      </GridItem>

      <GridItem title="Images and other media">
        <li>Processing images for best format&quality</li>
        <li>CDN integrations</li>
      </GridItem>

      <GridItem title="Tests & QA">
        <li>Unit tests for the UI components and API points</li>
        <li>Integration tests and QA</li>
      </GridItem>

      <GridItem title="Integrations">
        <li>Payments, emails, social networks</li>
        <li>Logging, backups, CDN</li>
      </GridItem>
    </Grid>
  </Wrapper>
)

const ListInner: FC = props => {
  const classes = usesStyles()
  return (
    <Inner className={classes.inner}>
      <ul className={classes.center}>{props.children}</ul>
    </Inner>
  )
}

const GridItem: FC<{ title: string }> = props => (
  <Grid item xs={12} md={6} lg={4}>
    <h3>{props.title}</h3>
    <ListInner>{props.children}</ListInner>
  </Grid>
)

const usesStyles = makeStyles(
  {
    inner: {
      textAlign: "left",
      display: "flex",
      marginBottom: "3em"
    },
    center: {
      margin: "auto",
      lineHeight: "1.6",
      paddingLeft: "20px",
      width: "410px"
    }
  },
  { name: "Offers" }
)
