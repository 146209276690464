class Anchor {
  id: string
  anchor: string
  static create = (id: string, prefix?: string) => new Anchor(id, prefix)
  constructor(id: string, prefix?: string) {
    this.id = id
    this.anchor = (prefix || "") + "#" + id
  }
}

export const nav = {
  home: {
    path: "/",
    anchors: {
      tech: Anchor.create("tech", "/"),
      team: Anchor.create("team", "/"),
      contact: Anchor.create("contact", "/")
    }
  },
  hiring: {
    path: "/hiring"
  },
  team: {
    path: "/team"
  },
  chyk: {
    path: "/chyk"
  }
}
