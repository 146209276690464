import { Hidden } from "@material-ui/core"
import React, { FC, ReactNode } from "react"
import { IconName, TechIcon } from "../../app/Icon"
import { IMG_PREFIX, IMG_SUFFIX } from "../../app/theme"

export const SLIDER_CONFIG = {
  enter: 300,
  exit: 500,
  transition: 6000
}

export type TSlide = {
  title: ReactNode
  description: ReactNode
  image: string
}

const PerfDescription: FC = () => (
  <>
    <a
      href="https://developers.google.com/web/updates/2018/07/search-ads-speed"
      target="_blank"
    >
      Speed is now a landing page factor for Google Search.
    </a>
    <p>
      <span>
        We have years of experience in web performance and we developing with
        the best practices right from the beginning.
      </span>
      <Hidden xsDown>
        <br />
        <span>
          We even wrote few opensource solutions to help with images
          optimisations:
        </span>
        &ensp;
        <a href="https://github.com/palessit/img-optim" target="_blank">
          Images optimisation script for AWS Lambda
        </a>
        &ensp; and &ensp;
        <a
          href="https://wordpress.org/plugins/page-cache-on-cloudflare/"
          target="_blank"
        >
          Wordpress plugin for it.
        </a>
      </Hidden>
    </p>
    <p style={{ textAlign: "center" }}>
      <a
        href="https://developers.google.com/speed/pagespeed/insights/?url=https%3A%2F%2Flesplatanes.co.uk%2F&tab=desktop"
        target="_blank"
      >
        Here
      </a>
      &ensp;
      <a
        href="https://developers.google.com/speed/pagespeed/insights/?url=https%3A%2F%2Fbetnbet.ru%2F&tab=desktop"
        target="_blank"
      >
        are
      </a>
      &ensp;
      <a
        href="https://developers.google.com/speed/pagespeed/insights/?url=https%3A%2F%2Fpalessit.com%2F&tab=desktop"
        target="_blank"
      >
        few
      </a>
      &ensp;
      <a
        href="https://developers.google.com/speed/pagespeed/insights/?url=https%3A%2F%2Fpoker.ru%2Fvinrejt-v-pokere%2F&tab=desktop"
        target="_blank"
      >
        examples
      </a>
      <span>&ensp;with top scores.</span>
      &ensp;
      <Pagespeed100 />
    </p>
  </>
)

const Pagespeed100: FC = () => (
  <TechIcon
    icon={IconName.pagespeed}
    style={{ height: "26px", width: "29px" }}
  />
)

const slide_delivery: TSlide = {
  title: "Digital products",
  description: (
    <>
      <p>
        We have delivered dozens of products, internal and for customers around
        the world. Most of them are mobile friendly and mobile-first.
      </p>
      <p>
        Based on your requirements we help on all aspects of web applications.
        Estimates, planning, design, development, QA, infrastructure, backups
        and SEO.
      </p>
    </>
  ),
  image: `${IMG_PREFIX}${IMG_SUFFIX}/img/undraw_online_transactions_02ka`
}

const slide_perf: TSlide = {
  title: <span>Performance matters</span>,
  description: <PerfDescription />,
  image: `${IMG_PREFIX}${IMG_SUFFIX}/img/undraw_fast_loading_0lbh`
}

const slide_typed: TSlide = {
  title: "Type not hype",
  description: (
    <>
      <p>
        Enterprise is famous for it's strict requirements and usage of strongly
        typed languages, such as C# or Java.
      </p>
      <p>
        Web development was everything else but typed... JavaScript, CSS, HTTP
        requests and DB requests are the highlights.
      </p>
      <p>
        But we've found the way!!! TypeScript, JSS, GragphQL and Prisma
        framework made our development way safer and stable.
      </p>
    </>
  ),
  image: `${IMG_PREFIX}${IMG_SUFFIX}/img/undraw_hacker_mind_6y85`
}

const slide_opensource: TSlide = {
  title: "Open-source",
  description: (
    <>
      <p>
        We've build a bunch of open-source libraries, that we use every working
        day! Here are few of them:
      </p>

      <ul>
        <li>
          <a href="https://github.com/palessit/chyk" target="_blank">
            Chyk is a framework for universal SPAs with React
          </a>
        </li>
        <li>
          <a href="https://github.com/palessit/slate-html-mui" target="_blank">
            Slate HTML editor with MaterialUI
          </a>
        </li>
        <li>
          <a href="https://github.com/palessit/img-optim" target="_blank">
            AWS lambda script to optimise images onfly
          </a>
        </li>
        <li>
          <a
            href="https://github.com/palessit/cloudflare-cache-page"
            target="_blank"
          >
            Cloudflare cache on workers and wordpress plugin
          </a>
        </li>
      </ul>
    </>
  ),
  image: `${IMG_PREFIX}${IMG_SUFFIX}/img/undraw_open_source_1qxw`
}

export const slides: TSlide[] = [
  slide_delivery,
  slide_perf,
  slide_typed,
  slide_opensource
]
