import { makeStyles } from "@material-ui/styles"
import React from "react"
import { Link } from "react-router-dom"
import { Inner } from "../common/inner"
import { Wrapper } from "../common/wrapper"
import { nav } from "./nav"

export const ContactFooter: React.FC = () => {
  const classes = useStyles()
  return (
    <Wrapper variant="primary">
      <h2 id={nav.home.anchors.contact.id}>Contact</h2>
      <p>Ping us and we will use our IDEs to implement your IDEAS!</p>
      <Inner className={classes.inner}>
        <div style={{ textAlign: "left" }}>
          <div>ООО «Палессит»</div>
          <div>УНП 193058395</div>
          <div>Адрес: г. Минск, ул. Ольшевского 24, пом. 7</div>
          <div>IBAN: BY56PJCB30120597091000000933 </div>
          <div>BIC: PJCBBY2X</div>
          <div>Приорбанк ОАО, г.Минск, ул.Тимирязева, 65а</div>
          <div></div>
          <div>
            Тел{" "}
            <a
              href="tel:+375292265557"
              className={classes.link}
              style={{ display: "inline" }}
            >
              +375297777297
            </a>
          </div>
        </div>
        {/* <div>
          <a href="mailto:alexi@palessit.com" className={classes.link}>
            alexi@palessit.com
          </a>
          <a href="tel:+375292265557" className={classes.link}>
            +37529 226 55 57
          </a>
          <div className={classes.link}>
            Belarus, Minsk
            <br />
            ul. Pinskaja 28А, Office 25
          </div>
        </div> */}
        {/* <picture className={classes.map}>
          <source
            srcSet={`${IMG_PREFIX}/w_800,f_webp${IMG_SUFFIX}/img/map-ya.jpg 1170w,
            ${IMG_PREFIX}/w_600,f_webp${IMG_SUFFIX}/img/map-ya.jpg 750w`}
            type="image/webp"
          />
          <source
            srcSet={`${IMG_PREFIX}/w_800,f_jpg${IMG_SUFFIX}/img/map-ya.jpg 1170w,
            ${IMG_PREFIX}/w_600,f_jpg${IMG_SUFFIX}/img/map-ya.jpg 750w`}
            type="image/jpg"
          />
          <img
            src={IMG_PREFIX + IMG_SUFFIX + "/img/map-ya.jpg"}
            className={classes.map}
            {...{ loading: "lazy" }}
          />
        </picture> */}
      </Inner>
      <Inner className={classes.linksInner}>
        <a className={classes.linksItem} href="https://github.com/palessit">
          Github
        </a>
        <a
          className={classes.linksItem}
          href="https://www.youtube.com/channel/UCl5a2QKSgz-tpdZrOERPpaQ?view_as=subscriber"
        >
          YouTube
        </a>
        <Link className={classes.linksItem} to="/chyk">
          Chyk
        </Link>
      </Inner>
    </Wrapper>
  )
}

const useStyles = makeStyles(
  {
    inner: {
      display: "grid",
      gridTemplateColumns: "repeat( auto-fit, minmax(280px, 1fr) )",
      justifyItems: "center",
      gridColumnGap: "16px",
      gridRowGap: "16px",
    },
    link: {
      display: "block",
      marginBottom: "1em",
    },
    map: {
      width: "100%",
      borderRadius: "4px",
    },
    linksInner: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "50px",
    },
    linksItem: {
      margin: "0 2em",
    },
  },
  { name: "ContactFooter" }
)
