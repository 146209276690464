import { Chyk } from "chyk"
import { routes } from "./app/app-routes"
import { LayoutRoot } from "./app/layout-root"
import { initHistory } from "./util/location"

declare global {
  interface Window {
    ssr_data: any
    ssr_statusCode: number
  }
}

new Chyk({
  routes,
  deps: {},
  history: initHistory(),
  component: LayoutRoot,
  statusCode: window.ssr_statusCode,
  data: window.ssr_data,
  el: document.getElementById("app")
})
