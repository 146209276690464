import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

type TWrapperProps = {
  variant?: "light" | "primary"
} & React.HTMLAttributes<HTMLDivElement>
export const Wrapper: React.FC<TWrapperProps> = props => {
  const classes = useStyles()
  const { variant = "light", className, ...rest } = props

  return (
    <div
      className={clsx(
        classes.wrapper,
        className,
        variant === "light" ? classes.light : classes.primary
      )}
      {...rest}
    />
  )
}

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      textAlign: "center",
      padding: theme.palessit.blockPaddingMobile,
      paddingTop: "56px",
      [theme.breakpoints.up("sm")]: {
        padding: theme.palessit.blockPadding,
        paddingTop: "72px"
      }
    },
    light: {
      color: theme.palessit.black,
      background: theme.palessit.lightBg
    },
    primary: {
      color: theme.palessit.whiteSemi,
      background: theme.palessit.ultraGradient
    }
  }),
  { name: "Wrapper" }
)
